<template>
    <div>
        <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <p class="text-h6 mb-0">Performance Info</p>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5">
                <v-text-field dense readonly label="No. of Worked Shift" v-model="object.shift_quantity"></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                <v-text-field dense readonly label="No. of Worked Projects" v-model="object.project_quantity"></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field dense readonly label="Average Rating" v-model="object.average_rating"></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <p class="text-h6 mb-0">Comments on promoter (Latest 3)</p>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <div :key="index" class="comments-separator" v-for="(comment, index) in remarks">
                    <span >{{ comment }}</span>
                </div>
            </v-col>
        </v-row>
	</div>
</template>


<script>

export default {
	name: 'PromoterPerformanceForm',
    data() {
		return {
			dialog: false,
			loading: false,
            object: {
                shift_quantity: 0,
                project_quantity: 0,
                average_rating: 0.0,
            },
            remarks: []
        }
	},
	methods: {
        updateObject: function(item, remarks) {
            this.object = item
            this.remarks = remarks
        }
	}
}

</script>


<style scoped>
/deep/ .theme--light.v-label--is-disabled {
    color: rgba(0, 0, 0, 0.6) !important;
}

/deep/ .theme--light.v-input input, /deep/ .theme--light.v-select .v-select__selections {
    color: rgba(0, 0, 0, 0.87) !important;
}

.comments-separator {
    border-bottom: 0.2px solid black;
    padding: 15px 0;
}

</style>