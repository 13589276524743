<template>
    <div>
        <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <p class="text-h5 mb-0" v-text="getTitleText()"></p>
                <p class="text-title mb-0" v-text="getPermissionText()" v-if="!loading && !hasPermission()"></p>
            </v-col>
        </v-row>
        <template v-if="hasPermission()">
            <profile-form ref="profileForm" :readonly="isReadonly()" :citizenships="citizenships" :races="races" :genders="genders" :shirt-sizes="shirt_sizes" :agencies="agencies" :fg-types="fg_types" :cpf-choices="cpf_choices" :employment-statuses="employment_statuses" @updated="openNotifier"></profile-form>
            <address-form ref="addressForm" :readonly="isReadonly()" :districts="districts" @updated="openNotifier"></address-form>
            <bank-form ref="bankForm" :readonly="isReadonly()" :banks="banks" @updated="openNotifier"></bank-form>
            <performance-form ref="performanceForm"></performance-form>
        </template>
        <v-row v-if="permissions.can_edit">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <small class="red--text">* Required field</small><br>
                <small class="red--text">Note: Date of PR is required if the citizenship is PR.</small>
            </v-col>
        </v-row>
        <message-notifier ref="notifier"></message-notifier>
	</div>
</template>


<script>

import { delay } from '@/utils/helper'
import MessageNotifier from '../../../shared/MessageNotifier.vue'
import PromoterProfileUpdateForm from './PromoterProfileUpdateForm.vue'
import PromoterAddressUpdateForm from './PromoterAddressUpdateForm.vue'
import PromoterBankUpdateForm from './PromoterBankUpdateForm.vue'
import PromoterPerformanceForm from './PromoterPerformanceForm.vue'

export default {
	name: 'PromoterForm',
    components: {
        messageNotifier: MessageNotifier,
        profileForm: PromoterProfileUpdateForm,
        addressForm: PromoterAddressUpdateForm,
        bankForm: PromoterBankUpdateForm,
        performanceForm: PromoterPerformanceForm,
    },
	data() {
		return {
            loading: true,
            filters: {
                action: this.$route.name === 'account-promoter-edit'?'edit':'view'
            },
            permissions: {
                can_view: false,
                can_edit: false,
            },
            races: [],
            genders: [],
            citizenships: [],
            districts: [],
            banks: [],
            shirt_sizes: [],
            agencies: [],
            employment_statuses: [],
            cpf_choices: [],
            fg_types: [],
		}
	},
	computed: {
		profileForm() {
			return this.$refs.profileForm
		},
		addressForm() {
			return this.$refs.addressForm
		},
		bankForm() {
			return this.$refs.bankForm
		},
        performanceForm() {
            return this.$refs.performanceForm
        },
		notifier() {
            return this.$refs.notifier
        }
	},
	mounted: function() {
        this.get()
	},
	methods: {
        get: function() {
			this.loading = true
			this.$store.dispatch('epanel/promoter/retrievePromoter', { id: this.$route.params.id, object: this.filters }).then((response) => {
                this.permissions = response.data.permissions

                delay(() => {
                    this.profileForm.updateObject(response.data.object.profile)
                    this.addressForm.updateObject(response.data.object.address)
                    this.bankForm.updateObject(response.data.object.bank)
                    this.performanceForm.updateObject(response.data.object.performance, response.data.object.remarks.remarks)
                }, 1500)

                if(this.permissions.can_edit){
                    this.citizenships = response.data.citizenships
                    this.races = response.data.races
                    this.genders = response.data.genders
                    this.districts = response.data.districts
                    this.banks = response.data.banks
                    this.shirt_sizes = response.data.shirt_sizes
                    this.fg_types = response.data.fg_types
                    this.cpf_choices = response.data.cpf_choices
                    this.employment_statuses = response.data.employment_statuses
                    this.agencies = response.data.agencies
                }
			}).catch((error) => {
                this.errors = error.errors
			})
		},
		openNotifier: function(item) {
            this.notifier.updateText(item.message)
            this.notifier.open()
        },
        getTitleText: function() {
            return this.$route.meta['title']
        },
        getPermissionText: function() {
            return 'You do not have permission to ' + (this.$route.name === 'account-promoter-edit'?'update':'view') + ' this promoter account'
        },
        hasPermission: function() {
            return this.permissions.can_view || this.permissions.can_edit
        },
        isReadonly: function() {
            return this.permissions.can_view || !this.permissions.can_edit?true:false
        }
	}
}

</script>