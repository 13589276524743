<template>
    <div>
        <v-form lazy-validation ref="form" v-model="valid">
            <v-row>
                <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
                    <p class="text-h6 mb-0">Address Info</p>
                </v-col>
                <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6" class="text-right"  v-if="!readonly">
                    <v-btn icon color="primary" @click="save"><v-icon>mdi-content-save</v-icon></v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-autocomplete dense clearable label="District*" :items="districts" :rules="rules.district" :error-messages="errors.district" v-model="object.district.value" v-if="!readonly"></v-autocomplete>
                    <v-text-field dense readonly label="District*" v-model="object.district.text" v-if="readonly"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <v-text-field dense :clearable="!readonly" :readonly="readonly" label="Block No." :error-messages="errors.block_number" v-model="object.block_number"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field dense :clearable="!readonly" :readonly="readonly" label="Street Name*" :rules="rules.street_name" :error-messages="errors.street_name" v-model="object.street_name"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field dense :clearable="!readonly" :readonly="readonly" label="Unit No." :error-messages="errors.unit_number" v-model="object.unit_number"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field dense :clearable="!readonly" :readonly="readonly" label="Building Name" :error-messages="errors.building_name" v-model="object.building_name"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field dense :clearable="!readonly" :readonly="readonly" label="Postal Code*" :rules="rules.postal_code" :error-messages="errors.postal_code" v-model="object.postal_code"></v-text-field>
                </v-col>
            </v-row>
        </v-form>
	</div>
</template>


<script>

import { copyObject } from '@/utils/helper'

export default {
	name: 'PromoterAddressUpdateForm',
    props: {
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },
        districts: {
            type: Array,
            required: false,
            default: () => []
        }
	},
    data() {
		return {
			valid: true,
			loading: false,
			object: { district: { text: '', value: '' } },
			rules: {
                district: [
                    (value) => !!value || 'District is required'
                ],
				street_name: [
					(value) => !!value || 'Street name is required'
				],
				postal_code: [
                    (value) => {
                        if(!value) {
                            return 'Postal code is required'
                        }

                        if(value.length !== 6) {
                            return 'Postal code must be 6 digits'
                        }

                        if(!/^\d+$/.test(value)) {
                            return 'Mobile number must be 6 digits'
                        }

                        return true
					}
				]
			},
			errors: {}
		}
	},
	computed: {
		form() {
			return this.$refs.form
		}
	},
	methods: {
        save: function() {
			if(!this.readonly && this.form.validate()){
				this.loading = true
				this.errors = {}
				this.form.resetValidation()

				var object = copyObject(this.object, ['district'])
				object['district'] = this.object.district.value

				this.$store.dispatch('epanel/promoter/updatePromoterAddress', { id: this.$route.params['id'], object: object }).then((response) => {
                    this.loading = false
                    this.$emit('updated', { message: response.message })
                }).catch((errors) => {
                    this.errors = errors.data.errors
                    this.loading = false
                })
			}
		},
        updateObject: function(item) {
            this.object = { district: item.district?item.district:'', block_number: item.block_number?item.block_number:'',
                            street_name: item.street_name?item.street_name:'', unit_number: item.unit_number?item.unit_number:'',
                            postal_code: item.postal_code?item.postal_code:'', building_name: item.building_name?item.building_name:''}
        }
	}
}

</script>


<style scoped>
/deep/ .theme--light.v-label--is-disabled {
    color: rgba(0, 0, 0, 0.6) !important;
}

/deep/ .theme--light.v-input input, /deep/ .theme--light.v-select .v-select__selections {
    color: rgba(0, 0, 0, 0.87) !important;
}

</style>