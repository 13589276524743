<template>
    <div>
        <v-form lazy-validation ref="form" v-model="valid">
            <v-row>
                <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
                    <p class="text-h6 mb-0">Basic Info</p>
                </v-col>
                <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6" class="text-right" v-if="!readonly">
                    <v-btn icon color="primary" @click="save"><v-icon>mdi-content-save</v-icon></v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pt-0">
                    <label class="v-label v-label--active v-label--is-disabled theme--light">Profile Picture</label>
                    <v-img contain class="my-3" :max-height="120" :max-width="150" :src="object.image_url" v-if="object.image_url"></v-img>
                    <p class="mb-0" v-if="!object.image_url">No profile picture yet</p>
                    <div v-if="!readonly">
                        <v-file-input ref="file" label="Profile Picture*" show-size accept="images/jpg, images/jpeg, images/png" :rules="rules.image" :error-messages="errors.image" @change="readFileData"></v-file-input>
                        <small class="red--text">Note: Only accepts PNG, JPG, JPEG file (Less than 2 MB). Recommended dimension is 400x400.</small>
                    </div>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field dense :clearable="!readonly" :readonly="readonly" label="NRIC First Name*" :rules="rules.first_name" :error-messages="errors.first_name" v-model="object.first_name"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field dense :clearable="!readonly" :readonly="readonly" label="NRIC Last Name*" :rules="rules.last_name" :error-messages="errors.last_name" v-model="object.last_name"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field dense :clearable="!readonly" :readonly="readonly" label="Alias Name" :rules="rules.alias_name" :error-messages="errors.alias_name" v-model="object.alias_name"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field dense :clearable="!readonly" :readonly="readonly" label="NRIC*" :rules="rules.nric" :error-messages="errors.nric" v-model="object.nric"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field dense :clearable="!readonly" :readonly="readonly" label="Email" :rules="rules.email" :error-messages="errors.email" v-model="object.email"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-select dense clearable label="Race*" :items="races" :rules="rules.race" :error-messages="errors.race" v-model="object.race.value" v-if="!readonly"></v-select>
                    <v-text-field dense readonly label="Race" v-model="object.race.text" v-if="readonly"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-select dense clearable label="Gender*" :items="genders" :rules="rules.gender" :error-messages="errors.gender" v-model="object.gender.value" v-if="!readonly"></v-select>
                    <v-text-field dense readonly label="Gender" v-model="object.gender.text" v-if="readonly"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-menu offset-y transition="scale-transition" min-width="auto" :close-on-content-click="false" v-model="birthDateMenu" v-if="!readonly">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field dense clearable label="Date of Birth*" v-bind="attrs" v-on="on" :rules="rules.dob" :error-messages="errors.dob" v-model="object.dob.value"></v-text-field>
                        </template>
                        <v-date-picker no-title color="primary" :min="getMinDOBDate()" :max="getMaxDOBDate()" v-model="object.dob.value" @input="birthDateMenu = false"></v-date-picker>
                    </v-menu>
                    <v-text-field dense readonly label="Date of Birth*" v-model="object.dob.text" v-if="readonly"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field dense :clearable="!readonly" :readonly="readonly" label="Mobile Number*" :rules="rules.mobile_number" :error-messages="errors.mobile_number" v-model="object.mobile_number"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-select dense clearable label="Citizenship*" :items="citizenships" :rules="rules.citizenship" :error-messages="errors.citizenship" v-model="object.citizenship.value" v-if="!readonly"></v-select>
                    <v-text-field dense readonly label="Citizenship*" v-model="object.citizenship.text" v-if="readonly"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-menu offset-y transition="scale-transition" min-width="auto" :close-on-content-click="false" v-model="prDateMenu"  v-if="!readonly">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field dense clearable label="Date of PR" v-bind="attrs" v-on="on" :rules="rules.dopr" :error-messages="errors.dopr" v-model="object.dopr.value"></v-text-field>
                        </template>
                        <v-date-picker no-title color="primary" :min="getMinDOBDate()" :max="getMaxDOPRDate()" v-model="object.dopr.value" @input="prDateMenu = false"></v-date-picker>
                    </v-menu>
                    <v-text-field dense readonly label="Date of PR*" v-model="object.dopr.text" v-if="readonly"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-select dense clearable label="Choice of CPF*" :items="cpfChoices" :rules="rules.cpf_choice" :error-messages="errors.cpf_choice" :menu-props="{top: false, offsetY: true}" v-model="object.cpf_choice.value" @change="updateSDLPayable" v-if="!readonly"></v-select>
                    <v-text-field dense readonly label="Choice of CPF*" v-model="object.cpf_choice.text" v-if="readonly"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-select dense clearable label="Shirt Size*" :items="shirtSizes" :rules="rules.shirt_size" :error-messages="errors.shirt_size" :menu-props="{top: false, offsetY: true}" v-model="object.shirt_size.value" v-if="!readonly"></v-select>
                    <v-text-field dense readonly label="Shift Size*" v-model="object.shirt_size.text" v-if="readonly"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-select dense clearable label="SHG Contribution" :items="agencies" :error-messages="errors.agency" :menu-props="{top: false, offsetY: true}" v-model="object.agency.value" v-if="!readonly"></v-select>
                    <v-text-field dense readonly label="Agency*" v-model="object.agency.text" v-if="readonly"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-select dense clearable label="F/G Type" :items="fgTypes" :error-messages="errors.fg_type" v-model="object.fg_type.value" v-if="!readonly"></v-select>
                    <v-text-field dense readonly label="F/G Type" v-model="object.fg_type.text" v-if="readonly"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-select dense clearable label="Employment Status" :items="employmentStatuses" :error-messages="errors.employment_status" v-model="object.employment_status.value" v-if="!readonly"></v-select>
                    <v-text-field dense readonly label="Employment Status" v-model="object.employment_status.text" v-if="readonly"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-menu offset-y transition="scale-transition" min-width="auto" :close-on-content-click="false" v-model="leaveDateMenu" v-if="!readonly">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field dense clearable label="Date of Resign" v-bind="attrs" v-on="on" :rules="rules.dol" :error-messages="errors.dol" v-model="object.dol.value"></v-text-field>
                        </template>
                        <v-date-picker no-title color="primary" v-model="object.dol.value" @input="leaveDateMenu = false"></v-date-picker>
                    </v-menu>
                    <v-text-field dense readonly label="Date of Resign" v-model="object.dol.text" v-if="readonly"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-checkbox dense :readonly="readonly" label="SDL Payable" :error-messages="errors.sdl" v-model="object.sdl" @change="updateCPFChoice"></v-checkbox>
                </v-col>
            </v-row>
            <v-row class="pb-2">
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <display-label label="Status" :text="object.status"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <display-label label="Date of Creation" :text="object.doc"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <display-label label="Date of Interview" :text="object.doi"></display-label>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <display-label label="Interviewer" :text="object.interviewer"></display-label>
                </v-col>
            </v-row>
        </v-form>
	</div>
</template>


<script>

import { makeDate, today } from '@/utils/dates'
import { encodeBase64 } from '@/utils/encodes'
import { copyObject } from '@/utils/helper'
import DisplayLabel from '../../../shared/DisplayLabel.vue'

export default {
	name: 'PromoterProfileUpdateForm',
	components: {
        displayLabel: DisplayLabel
    },
	props: {
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },
        races: {
            type: Array,
            required: false,
            default: () => []
        },
        genders: {
            type: Array,
            required: false,
            default: () => []
        },
        citizenships: {
            type: Array,
            required: false,
            default: () => []
        },
        cpfChoices: {
            type: Array,
            required: false,
            default: () => []
        },
        shirtSizes: {
            type: Array,
            required: false,
            default: () => []
        },
        agencies: {
            type: Array,
            required: false,
            default: () => []
        },
        fgTypes: {
            type: Array,
            required: false,
            default: () => []
        },
        employmentStatuses: {
            type: Array,
            required: false,
            default: () => []
        }
	},
    data() {
		return {
			valid: true,
			loading: false,
			birthDateMenu: false,
			prDateMenu: false,
			leaveDateMenu: false,
            object: {
                race: { text: '', value: '' },
                gender: { text: '', value: '' },
                dob: { text: '', value: '' },
                citizenship: { text: '', value: '' },
                fg_type: { text: '', value: '' },
                employment_status: { text: '', value: '' },
                cpf_choice: { text: '', value: '' },
                shirt_size: { text: '', value: '' },
                agency: { text: '', value: '' },
                dopr: { text: '', value: '' },
                dol: { text: '', value: '' },
            },
			rules: {
				first_name: [
					(value) => !!value || 'First name is required',
					(value) => value && value.length >= 1 && value.length <= 100 || 'First name must be between 1 and 100 characters'
				],
				last_name: [
					(value) => !!value || 'Last name is required',
					(value) => value && value.length >= 1 && value.length <= 100 || 'Last name must be between 1 and 100 characters'
				],
                alias_name: [
                    (value) => {
                        if(value && value.length < 1 && value.length > 100) {
                            return 'Alias name must be between 1 and 100 characters'
                        }

                        return true
                    }
                ],
				nric: [
                    (value) => !!value || 'NRIC is required',
					(value) => value && value.length == 9 || 'NRIC must be 9 characters'
				],
				email: [
					(value) => {
                        if(value && !/.+@.+\..+/.test(value)){
                            return 'Email is invalid'
                        }

                        return true
                    }
				],
				race: [
					(value) => !!value || 'Gender is required'
				],
                gender: [
                    (value) => !!value || 'Gender is required'
                ],
				citizenship: [
					(value) => !!value || 'Citizenship is required'
				],
				dob: [
					(value) => !!value || 'Date of birth is required'
				],
				mobile_number: [
                    (value) => {
                        if(!value) {
                            return 'Mobile number is required'
                        }

                        if(value.length !== 8) {
                            return 'Postal code must be 8 digits'
                        }

                        if(!/^\d+$/.test(value)) {
                            return 'Mobile number must be 8 digits'
                        }

                        if(!['8', '9'].includes(value.substring(0, 1))) {
                            return 'Mobile number must starts with 8 or 9'
                        }

                        return true
					}
				],
				dopr: [
                    (value) => {
                        const citizenship = this.object.citizenship.value
                        if(!value && citizenship && citizenship.toLowerCase() === 'pr'){
                            return "Date of PR is required"
                        }

                        return true
                    }
				],
                cpf_choice: [
                    (value) => !!value || "CPF Choice is required"
                ],
                shirt_size: [
                    (value) => !!value || "Shift size is required"
                ],
                dol: [
                    (value) => {
                        if(this.object.employment_status === 'L' || this.object.employment_status === 'NL'){
                            if(!value){
                                return "Date of resign is required"
                            }
                        }
                        return true
                    }
                ],
                image: [
                    (value) => {
                        if(!value) {
                            return true
                        }

                        var fileSize = Math.ceil(value.size / 1024)

                        if(fileSize > 2048) {
                            return 'Image File must less than 2MB'
                        }

                        return true
                    }
                ]
			},
			errors: {}
		}
	},
	computed: {
		form() {
			return this.$refs.form
		}
	},
    methods: {
        getMinDOBDate: function() {
            return makeDate(new Date().getFullYear() - 80, 1, 1)
        },
        getMaxDOBDate: function() {
            return makeDate(new Date().getFullYear() - 18, 12, 31)
        },
        getMaxDOPRDate: function() {
            return today()
        },
        readFileData: function(file) {
            if(!file){
                return
            }

            var reader = new FileReader()
            reader.readAsDataURL(file)
            reader.addEventListener('load', (e) => {
                this.object.image = e.target.result
            })
        },

		save: function() {
			if(!this.readonly && this.form.validate()){
				this.loading = true
				this.errors = {}
				this.form.resetValidation()

                var object = copyObject(this.object, ['nric', 'gender', 'dob', 'image', 'fg_type', 'citizenship', 'race',
                                                      'cpf_choice', 'shirt_size', 'agency', 'dopr', 'dol', 'employment_status'])
                object['nric'] = encodeBase64(this.object.nric)
                object['race'] = this.object.race.value
                object['gender'] = this.object.gender.value
                object['dob'] = this.object.dob.value
                object['citizenship'] = this.object.citizenship.value
                object['fg_type'] = this.object.fg_type.value
                object['employment_status'] = this.object.employment_status.value
                object['cpf_choice'] = this.object.cpf_choice.value
                object['shirt_size'] = this.object.shirt_size.value
                object['agency'] = this.object.agency.value
                object['dopr'] = this.object.dopr.value
                object['dol'] = this.object.dol.value
                object['image'] = this.object.image

				this.$store.dispatch('epanel/promoter/updatePromoterProfile', { id: this.$route.params.id, object: object }).then((response) => {
                    this.loading = false
                    this.$emit('updated', { message: response.message })
                }).catch((errors) => {
                    this.errors = errors.data.errors
                    this.loading = false
                })
			}
		},
        updateObject: function(item) {
            this.object = item
        },
        updateSDLPayable: function() {
            if(this.object.cpf_choice.value === 'Y'){
                this.object.sdl = true
            }else if(this.object.cpf_choice.value === 'N') {
                this.object.sdl = false
            }
        },
        updateCPFChoice: function() {
            if(this.object.sdl){
                this.object.cpf_choice.value = 'Y'
            }else{
                this.object.cpf_choice.value = 'N'
            }
        },
	}
}

</script>

<style scoped>
/deep/ .theme--light.v-label--is-disabled {
    color: rgba(0, 0, 0, 0.6) !important;
}

/deep/ .theme--light.v-input input, /deep/ .theme--light.v-select .v-select__selections {
    color: rgba(0, 0, 0, 0.87) !important;
}

.v-label {
    font-size: 12px !important;
}

</style>