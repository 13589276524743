<template>
    <div>
        <v-form lazy-validation ref="form" v-model="valid">
            <v-row>
                <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
                    <p class="text-h6 mb-0">Bank Info</p>
                </v-col>
                <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6" class="text-right" v-if="!readonly">
                    <v-btn icon color="primary" @click="save"><v-icon>mdi-content-save</v-icon></v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5">
                    <v-autocomplete dense clearable label="Bank*" :disabled="loading" :items="banks" :rules="rules.bank" :error-messages="errors.bank" v-model="cachedObject.bank.value" @change="updateBranchCode" v-if="!readonly"></v-autocomplete>
                    <v-text-field dense readonly label="Bank*" v-model="cachedObject.bank.text" v-if="readonly"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-text-field dense :clearable="!readonly" :readonly="readonly" label="Branch Code*" :error-messages="errors.branch_code" v-model="cachedObject.branch_code"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field dense :clearable="!readonly" :readonly="readonly" label="Account Number*" :error-messages="errors.account_number" v-model="cachedObject.account_number"></v-text-field>
                </v-col>
            </v-row>
        </v-form>
	</div>
</template>


<script>

import { encodeBase64, decodeBase64 } from '@/utils/encodes'

export default {
	name: 'PromoterBankUpdateForm',
    props: {
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },
        banks: {
            type: Array,
            required: false,
            default: () => []
        }
	},
    data() {
		return {
			dialog: false,
			isDisabledBranch: false,
			valid: true,
			loading: false,
            object: {
                bank: '',
                branch_code: '',
                account_number: '',
            },
            cachedObject: {
                bank: { text: '', value: '' },
                branch_code: '',
                account_number: ''
            },
			rules: {
				bank: [
					(value) => !!value || 'Bank is required'
				],
                branch_code: [
                    (value) => {
                        if(this.object.bank && this.banks.length > 0){
                            const bank = this.banks.filter(item => item.value === this.object.bank)[0]
                            if(["DBS", "POSB", "OCBC", "UOB"].indexOf(bank.code) > -1){
                                this.isDisabledBranch = true
                                return true
                            }else{
                                this.isDisabledBranch = false
                            }
                        }

                        if(!value) {
                            return 'Branch code is required'
                        }

                        if(value.length < 3 || value.length > 10){
                            return 'Branch code must be between 3 and 10 characters'
                        }

                        if(!/^\d+{3, 10}$/.test(value)) {
                            return 'Branch code must be numeric value'
                        }

                        return true
                    }
                ],
                account_number: [
                    (value) => {
                        if(!value) {
                            return "Account number is required"
                        }

                        if(this.object.bank) {
                            var bank = this.banks.filter(item => item.value === this.object.bank)[0]
                            if(bank.account_digits.indexOf(value.length.toString()) === -1){
                                return 'Account number must ' + bank.account_digits.join(' or ') + ' characters'
                            }
                        }

                        if(!/^\d+$/.test(value)){
                            return 'Account number must be numeric value'
                        }

                        return true
                    }
                ],
			},
			errors: {}
		}
	},
	computed: {
		form() {
			return this.$refs.form
		}
	},
	methods: {
        updateBranchCode: function(index) {
            const bank = index?this.banks.filter(item => item.value === index)[0]:false
            this.isDisabledBranch = bank && ["DBS", "POSB", "OCBC", "UOB"].indexOf(bank.code) > -1?true:false
		},
        save: function() {
			if(!this.readonly && this.form.validate()){
				this.loading = true
				this.errors = {}
				this.form.resetValidation()

                var object = { bank: this.cachedObject.bank.value, branch_code: encodeBase64(this.cachedObject.branch_code),
                               account_number: encodeBase64(this.cachedObject.account_number) }

				this.$store.dispatch('epanel/promoter/updatePromoterBank', { id: this.$route.params.id, object: object }).then((response) => {
                    this.loading = false
                    this.$emit('updated', { message: response.message })
                }).catch((errors) => {
                    this.errors = errors.data.errors
                    this.loading = false
                })
			}
		},
        updateObject: function(item) {
            this.object = { bank: item.bank?item.bank:'', branch_code: item.branch_code?item.branch_code:'', account_number: item.account_number?item.account_number:''}
            this.cachedObject = { bank: item.bank?item.bank:'', branch_code: item.branch_code?decodeBase64(this.object.branch_code):'', account_number: item.account_number?decodeBase64(this.object.account_number):''}
        }
	}
}

</script>


<style scoped>
/deep/ .theme--light.v-label--is-disabled {
    color: rgba(0, 0, 0, 0.6) !important;
}

/deep/ .theme--light.v-input input, /deep/ .theme--light.v-select .v-select__selections {
    color: rgba(0, 0, 0, 0.87) !important;
}

</style>